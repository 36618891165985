html,
body,
#root {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'zh quote', -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Noto Sans', 'Noto Sans CJK SC',
    'Microsoft YaHei UI', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  color: #424242;
  box-sizing: border-box;
  background: #f5f5f5;
}
/* todo delete */
/* iframe{display: none;} */

/* h5页面如何禁止弹性下拉 */
@media screen and (max-width: 960px) {
  html {
    overflow: hidden;
  }
  body {
    /* overflow: scroll; */
    overflow: hidden;
  }
}

*:not(input):not(textarea) {
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 5px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 8px;
}
::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
  background: '#474747';
}
::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 2px;
  background-color: #4b53bc;
}

/* 隐藏版权信息 */
.fr-wrapper > div:first-child,
.fr-view > p[data-f-id='pbf'] {
  opacity: 0 !important;
  pointer-events: none;
}

.tableWrapper tbody > tr:nth-of-type(odd) {
  background-color: unset;
}

.CodeMirror,
.react-codemirror2 {
  height: 100% !important;
}
.CodeMirror-vscrollbar::-webkit-scrollbar {
  width: 0.4em;
}
.CodeMirror-vscrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
  background-color: #949596;
  outline: 1px solid slategrey;
}
.fr-view > hr {
  border: 1px solid #e8ecf1 !important;
}
/* 文档模式下划线样式 */
@media (min-width: 992px) {
  .fr-box.fr-document .fr-wrapper .fr-element hr {
    margin-left: unset !important;
    margin-right: unset !important;
    background: #e8ecf1;
    border-color: #e8ecf1;
    height: unset !important;
    outline: unset !important;
    border: 1px solid #e8ecf1 !important;
  }

  .fr-box.fr-document,
  .fr-box.fr-document .fr-wrapper {
    min-width: unset !important;
  }
  .fr-box.fr-document .fr-wrapper .fr-element {
    /* width: unset !important; */
    padding: 1cm !important;
  }
}
.MuiTreeItem-label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* markdown */
.preview-container pre {
  border: 1px solid #ccc;
}

.preview-container blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}

.preview-container tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

.preview-container th,
.preview-container td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.preview-container table tr:nth-child(2n) {
  background: #f6f8fa;
}
/* dplayer */
.dplayer-logo {
  pointer-events: none;
  position: absolute;
  left: unset !important;
  right: 20px !important;
  top: 20px;
  max-width: 50px;
  max-height: 50px;
  width: 35px;
}
/* 屏蔽dplayer右键菜单（非第一个） */
.dplayer-menu.dplayer-menu-show > div:not(:first-child) {
  display: none;
}

.MuiInputBase-input.MuiInput-input {
  text-overflow: ellipsis;
}
